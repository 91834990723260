import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { LinkedInIcon, TwitterIcon, GithubIcon,SpeakerDeckIcon,MediumIcon } from "../assets/svgs";
export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            home {
              title
              description {
                item
              }
            }
            socials {
              linkedIn
              twitter
              github
              speakerdeck
              medium
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className="hero-header">
       {/* <br />
        <br />
        <br />
         <div className="headline">{data.site.siteMetadata.home.title}</div>
        <br /> */}
      
        {data.site.siteMetadata.home.description.map(
          (data,index)=>{return <div className="primary-content">{data.item}</div>}
          )
        }
     <Link to="/public-speaking" className="button -primary">Public Speaking</Link>
      <br />
      <br />
      <br />

      {/* <h2><u>Public Speaking</u></h2> */}
        <div className="social-icons">
          <a href={data.site.siteMetadata.socials.linkedIn} target="_blank">
            <LinkedInIcon />
          </a>
          <a href={data.site.siteMetadata.socials.github} target="_blank">
            <GithubIcon />
          </a>
          <a href={data.site.siteMetadata.socials.speakerdeck} target="_blank">
            <SpeakerDeckIcon />
          </a>
          <a href={data.site.siteMetadata.socials.twitter} target="_blank">
            <TwitterIcon />
          </a>
          <a href={data.site.siteMetadata.socials.medium} target="_blank">
            <MediumIcon />
          </a>
        </div>
        <br />
      </div>
    )}
  />
);
